import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'

// Base Page
import BasePage from '@/pages/Base'

// Services
import UserSettingsService from '@/services/UserSettings'

@Component({})
export default class ManageAgent extends BasePage {
  constructor() {
    super()
  }

  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search By Name...'
  searchHint: string = ''
  searchIcon: string = 'search'

  // Table
  tableHeaders: object[] = [
    {
      text: 'Name',
      align: 'left',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Email',
      align: 'left',
      sortable: false,
      value: 'email'
    },
    {
      text: 'Phone',
      align: 'left',
      sortable: false,
      value: 'phone'
    },
    {
      text: 'Member Order',
      align: 'center',
      sortable: false,
      value: 'commision_orders'
    },
    {
      text: 'Credit Balance',
      align: 'right',
      sortable: true,
      value: 'balance'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false

  businessList: any = ['corporation', 'individual', 'partnership', 'empty']
  membershipsList: any = []
  rolesList: any = []
  recomendationList: any = []
  recomendationListSub: any = []
  userDialog: boolean = false
  userDialogData: any = {}

  useAsEdit: boolean = false
  selectedEditId: string | number = null

  countries: any = []
  getCountriesLoading: boolean = false

  filter: any = {
    items: ['All Time', 'Year', 'Last Month', 'This Month', 'Last 7 Days', 'Custom'],
    from: {
      selected: null,
      keyword: null
    },
    label: 'Filter by Commission Date',
    hint: 'Apply Filter by Commission Date Here',
    icon: 'mdi-filter-variant',
    loading: false
  }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getUsers()
  }

  // @Watch('filter.from.selected', { deep: true })
  // async onFilterMembershipsChange() {
  // }

  async onClearSearch() {
    this.searchInput = ''
    await this.getUsers()
  }

  async getUsers() {
    this.tableLoading = true
    const opts: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        // 'filter[memberships.name][in]': 'Partner',
        'filter[is_partner][is]': 1,
        sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy,
        include: 'items,invoices,platforms,parents,childs,codes'
      }
    }

    // if (this.tablePagination.sortBy === 'balance') {
    //   opts.params = {
    //     ...opts.params,
    //     sort_credit: this.tablePagination.descending ? 'desc' : 'asc'
    //   }
    // } else {
    //   opts.params = {
    //     ...opts.params,
    //     sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy
    //   }
    // }

    if (this.searchInput != '') {
      opts.params = {
        ...opts.params,
        'filter[name][like]': this.searchInput
      }
    } else {
      var textFilter = 'filter[name][like]'
      delete opts.params[textFilter]
    }

    try {
      const response = await UserSettingsService.getUsers(opts)
      const responseData = response.data.data
      const responseMeta = response.data.meta

      this.tableItems = []
      forEach(responseData, dataUser => {

        const user = dataUser.attributes
        if (!user.balance) {
          user.balance = 0
        }

        if (!user.phone) {
          user.phone = '-'
        }

        if (user.country_signup) {
          user.currency = response.data.included.signupcountry[user.country_signup].attributes.currency
        } else {
          user.currency = 'IDR'
        }

        this.tableItems.push(user)
      })

      this.tableTotalItems = responseMeta.pagination.total
      this.tableLoading = false
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }
}
